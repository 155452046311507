import styled from 'styled-components';

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 520px;
`;

export const StyledInput = styled.input`
  width: 100%;
  border: 2px solid rgba(89, 89, 89, 0.2);
  background: none;
  margin-bottom: 15px;
  padding: 30px 15px 10px 15px;
  font-size: 18px;
  color: ${({ theme }) => theme.color.text.primary};
  button {
    border: 2px solid rgba(89, 89, 89, 0.2);
    background: none;
  }
  max-width: calc(100% - 4px - 30px); /* 100% - border - padding */
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  border: 2px solid rgba(89, 89, 89, 0.2);
  background: none;
  min-height: 270px;
  padding: 30px 15px 15px 15px;
  font-size: 18px;
  color: ${({ theme }) => theme.color.text.primary};
  margin-bottom: 50px;
  max-width: calc(100% - 4px - 30px);
`;

export const ExtraPadding = styled.div`
  padding-bottom: 100px;
`;
