import { Link } from 'gatsby';
import styled, { DefaultTheme, ThemeProps } from 'styled-components';

interface ImageProps extends ThemeProps<DefaultTheme> {
  imageLink: string;
}

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 150px;
`;

export const MemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  @media screen and (min-width: ${({ theme }) => theme.layout.sm}px) {
    width: calc(50% - 5px);
    margin-bottom: 10px;
    margin-right: 10px;
    :nth-child(2n) {
      margin-right: 0px;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    width: calc(50% - 10px);
    margin-bottom: 20px;
    margin-right: 20px;
    :nth-child(2n) {
      margin-right: 0px;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    margin-right: 15px;
    margin-bottom: 15px;
    :nth-child(2n) {
      margin-right: 15px;
    }
    :nth-child(3n) {
      margin-right: 0px;
    }
    width: calc(100% / 3 - 10px);
  }
`;

export const MemberPhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  @media screen and (min-width: ${({ theme }) => theme.layout.sm}px) {
    height: 300px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    height: 350px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    height: 323px;
  }
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ imageLink }: ImageProps) => imageLink});
`;

export const MemberSocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  :hover {
    opacity: 1;
  }
`;

export const SocialLink = styled.a`
  width: 60px;
  height: 60px;
  padding: 10px;
  border: none;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 10px;
  color: ${({ theme }) => theme.color.text.accent};
  background: ${({ theme }) => theme.color.text.primary};
  :hover {
    color: ${({ theme }) => theme.color.text.primary};
    background: ${({ theme }) => theme.color.text.accent};
  }
`;

export const MemberName = styled.h4`
  color: ${({ theme }) => theme.color.text.primary};
  font-family: 'Roboto Mono';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 20px 0 10px 0;
`;

export const MemberPosition = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.text.secondary};
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 10px;
`;

export const JoinUsLink = styled(Link)`
  width: 100%;
  height: 350px;
  border: 2px dashed ${({ theme }) => theme.color.text.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.layout.sm}px) {
    height: 300px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    height: 350px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    height: 323px;
  }
`;

export const JoinIconWrapper = styled.div`
  width: 100px;
  height: 100px;
`;
