import { TitleText } from 'components/CommonComponents/Titles/styled';
import styled, { DefaultTheme, ThemeProps } from 'styled-components';

interface ImageProps extends ThemeProps<DefaultTheme> {
  imageLink: string;
  visibilityOnSmall?: boolean;
}

export const Wrapper = styled.div`
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ParagraphHeader = styled.h2`
  font-size: 50px;
  letter-spacing: 6px;
  margin-bottom: 40px;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: 700;
  max-width: 100%;
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    max-width: 75%;
    line-height: 70px;
    font-size: 60px;
    text-align: left;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    max-width: 60%;
    line-height: 104px;
    font-size: 80px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 100%;
  font-size: 18px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.text.secondary};
  margin-bottom: 50px;
  min-height: 200px;
`;

export const ParagraphImage = styled.img`
  max-width: 100%;
  display: initial;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    display: none;
  }
`;

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Photo = styled.img`
  cursor: pointer;
  max-width: 300px;
  height: 200px;
  object-fit: cover;
  width: 100%;
`;

export const GoalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 50px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    margin-bottom: 150px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
`;

export const Separator = styled.div`
  margin-bottom: 175px;
`;

export const GoalWrapper = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 10px;
  margin-bottom: 50px;
  img {
    align-self: center;
    width: 60px;
    height: auto;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    max-width: 320px;
    align-self: initial;
    :nth-child(odd) {
      margin-right: auto;
    }
  }
`;

export const GoalHeader = styled.h3`
  color: ${({ theme }) => theme.color.text.primary};
  align-self: center;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  padding-top: 20px;
`;

export const GoalText = styled.div`
  font-size: 18px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.text.secondary};
  text-align: center;
`;

export const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  margin-bottom: 150px;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: flex-start;
  margin-bottom: 100px;
  background-image: none;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    /* max-width: 50%; */
    background-image: url(${({ imageLink }: ImageProps) => imageLink});
    padding-right: 400px;
    background-position: 100% 50%;
    :nth-child(2) {
      align-self: flex-end;
      background-position: 0% 50%;
      padding-left: 400px;
      padding-right: 0px;
    }
  }
`;

export const ValueHeader = styled.h4`
  color: ${({ theme }) => theme.color.text.primary};
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const ValueText = styled.div`
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.text.secondary};
`;

export const ValueImage = styled.img`
  margin: 0 auto;
  display: initial;
  margin-bottom: 50px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    display: none;
  }
`;

export const VisionText = styled.p`
  align-self: center;
  text-align: center;
  max-width: 80%;
  font-size: 18px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.text.secondary};
  margin-top: 0px;
  margin-bottom: 250px;
`;

export const Star1 = styled.img`
  position: relative;
  align-self: flex-start;
  max-height: 18px;
  width: auto;
  left: 0px;
  top: 170px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    top: 0px;
  }
`;

export const Star2 = styled.img`
  position: relative;
  align-self: flex-start;
  max-height: 18px;
  width: auto;
  left: 250px;
  top: -50px;
`;

export const Star3 = styled.img`
  position: relative;
  align-self: flex-start;
  max-height: 18px;
  width: auto;
  left: 50px;
  top: 0px;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    left: 100px;
  }
`;

export const Star4 = styled.img`
  position: relative;
  align-self: flex-start;
  max-height: 18px;
  width: auto;
  left: 200px;
  top: 0px;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    left: 300px;
    top: 100px;
  }
`;

export const StyledImagePhone1 = styled.img`
  position: relative;
  align-self: flex-start;
  max-width: 127px;
  height: auto;
  top: 630px;
  right: -100px;
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    right: -500px;
    top: 400px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    top: 480px;
    left: 50px;
    display: initial;
  }
`;

export const StyledImagePhone2 = styled.img`
  position: relative;
  align-self: flex-end;
  max-width: 108px;
  height: auto;
  right: 0px;
  top: -50px;
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    right: 50px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    right: -150px;
    top: 400px;
    display: initial;
  }
`;

export const SectionWithBackground = styled.section`
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 50px;
  background-position: 90% 30%;

  background-image: url(${({ imageLink, visibilityOnSmall }: ImageProps) => (visibilityOnSmall ? imageLink : '')});
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    background-image: url(${({ imageLink }: ImageProps) => imageLink});
  }
  @media screen and (max-width: ${({ theme }) => theme.layout.lg}px) {
    background-position: 90%;
    background-size: 50%;
  }
`;

export const ParagraphWrapper = styled.div`
  align-self: flex-start;
  margin-bottom: 50px;
  img {
    margin-bottom: 50px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    max-width: 66%;
    margin-bottom: 150px;
    h1 {
      text-align: start;
    }
    :nth-child(odd) {
      margin-left: 75px;
    }
  }
`;

export const SecondParagraphWrapper = styled(ParagraphWrapper as any)`
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    width: 50%;
  }
`;

export const SecondParagraphTitle = styled(TitleText as any)`
  text-align: start;
`;
