import { Col, Row } from 'antd';
import Footer from 'components/CommonComponents/Footer';
import MessageUs from 'components/CommonComponents/Forms/MessageUsForm';
import NavigationBar from 'components/CommonComponents/NavigationBar';
import SEO from 'components/CommonComponents/SEO';
import { MainTitleText, TitleText } from 'components/CommonComponents/Titles/styled';
import { MainContainer, StyledSection } from 'components/CommonComponents/Wrappers/styled';
import {
  GoalHeader,
  GoalsWrapper,
  GoalText,
  GoalWrapper,
  ParagraphImage,
  ParagraphWrapper,
  Photo,
  SecondParagraphTitle,
  SecondParagraphWrapper,
  SectionWithBackground,
  Separator,
  Star1,
  Star2,
  Star3,
  Star4,
  StyledImagePhone1,
  StyledImagePhone2,
  TextWrapper,
  ValueHeader,
  ValueImage,
  ValuesWrapper,
  ValueText,
  ValueWrapper,
  VisionText,
} from 'components/PageAboutUs/styled';
import TeamMembers from 'components/PageAboutUs/TeamMembers';
import { GlobalStyle } from 'constants/globalTheme';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const pageQuery = graphql`
  {
    messageusYaml {
      header {
        cs
      }
      email {
        cs
      }
      buttonText {
        cs
      }
      message {
        cs
      }
      name {
        cs
      }
    }
    allOurgoalslistYaml {
      nodes {
        heading {
          cs
        }
        text {
          cs
        }
      }
    }
    allValueslistYaml {
      nodes {
        heading {
          cs
        }
        text {
          cs
        }
        image {
          publicURL
        }
      }
    }
    aboutusYaml {
      backgroundImages {
        publicURL
      }
      showcasingImages {
        publicURL
      }
      paragraphs {
        backgroundImage {
          publicURL
        }
        heading {
          cs
        }
        text {
          cs
        }
      }
      vision {
        heading {
          cs
        }
        text {
          cs
        }
      }
      team {
        heading {
          cs
        }
        joinUsText {
          cs
        }
      }
      ourGoals {
        heading {
          cs
        }
        image {
          publicURL
        }
      }
      values {
        heading {
          cs
        }
      }
    }
  }
`;

const AboutUs = () => {
  const data = useStaticQuery(pageQuery);

  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);

  const locale = 'cs';
  const photos = data.aboutusYaml.showcasingImages.map((a: any) => a.publicURL);
  const messageUsData = data.messageusYaml;
  const ourGoalsData = data.allOurgoalslistYaml.nodes;
  const ourValuesData = data.allValueslistYaml.nodes;

  const firstPara = data.aboutusYaml.paragraphs[0];
  const secondPara = data.aboutusYaml.paragraphs[1];
  const bgImages = data.aboutusYaml.backgroundImages.map((a: any) => a.publicURL);
  const goalsData = data.aboutusYaml.ourGoals;
  const valuesData = data.aboutusYaml.values;
  const visionData = data.aboutusYaml.vision;
  const teamData = data.aboutusYaml.team;

  return (
    <>
      <SEO title="O nás" />
      <GlobalStyle />

      <NavigationBar />
      <MainContainer>
        <SectionWithBackground imageLink={firstPara.backgroundImage.publicURL}>
          <StyledSection as="div">
            <Separator />
            <ParagraphWrapper>
              <ParagraphImage src={firstPara.backgroundImage.publicURL} />
              <MainTitleText>{firstPara.heading[locale]}</MainTitleText>
              <TextWrapper>{firstPara.text[locale]}</TextWrapper>
            </ParagraphWrapper>
          </StyledSection>
        </SectionWithBackground>
        <SectionWithBackground imageLink={secondPara.backgroundImage.publicURL}>
          <StyledSection as="div">
            <SecondParagraphWrapper>
              <SecondParagraphTitle>{secondPara.heading[locale]}</SecondParagraphTitle>
              <TextWrapper>{secondPara.text[locale]}</TextWrapper>
            </SecondParagraphWrapper>
          </StyledSection>
        </SectionWithBackground>
        <StyledSection>
          <Row gutter={[16, 16]} justify="center" align="top">
            {photos.map((photo: string, index: number) => (
              <Col
                key={index}
                onClick={() => {
                  setPhotoIndex(index);
                  setLightboxOpen(true);
                }}
              >
                <Photo src={photo} />
              </Col>
            ))}
          </Row>
          {lightboxOpen && (
            <Lightbox
              mainSrc={photos[photoIndex]}
              nextSrc={photos[(photoIndex + 1) % photos.length]}
              prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
              onCloseRequest={() => setLightboxOpen(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)}
            />
          )}
        </StyledSection>
        <Separator />
        <StyledSection>
          <TitleText>{goalsData.heading[locale]}</TitleText>
          <GoalsWrapper>
            {ourGoalsData.map(({ heading, text, image }: any, index: number) => (
              <GoalWrapper key={`goal-${index}`}>
                <img src={goalsData.image.publicURL} />
                <GoalHeader>{heading[locale]}</GoalHeader>
                <GoalText>{text[locale]}</GoalText>
              </GoalWrapper>
            ))}
          </GoalsWrapper>
        </StyledSection>
        <StyledSection>
          <TitleText>{valuesData.heading[locale]}</TitleText>
          <ValuesWrapper>
            {ourValuesData.map((value: any, index: number) => (
              <ValueWrapper key={index} imageLink={value.image.publicURL}>
                {index === 0 && <StyledImagePhone2 src={bgImages[5]} />}
                {index === 0 && <Star1 src={bgImages[0]} />}
                {index === 1 && <Star2 src={bgImages[1]} />}
                {index === 2 && <StyledImagePhone1 src={bgImages[4]} />}
                {index === 2 && <Star3 src={bgImages[2]} />}
                <ValueImage src={value.image.publicURL} />
                <ValueHeader>{value.heading[locale]}</ValueHeader>
                <ValueText>{value.text[locale]}</ValueText>
              </ValueWrapper>
            ))}
          </ValuesWrapper>
        </StyledSection>
        <StyledSection>
          <Star4 src={bgImages[3]} />
          <TitleText>{visionData.heading[locale]}</TitleText>
          <VisionText>{visionData.text[locale]}</VisionText>
        </StyledSection>
        <StyledSection>
          <TitleText>{teamData.heading[locale]}</TitleText>
          <TeamMembers joinUsText={teamData.joinUsText[locale]} />
        </StyledSection>
        <MessageUs
          buttonText={messageUsData.buttonText[locale]}
          emailPlaceholder={messageUsData.email[locale]}
          imageLink={messageUsData.backgroundImage}
          mainHeading={messageUsData.header[locale]}
          messagePlaceholder={messageUsData.message[locale]}
          namePlaceholder={messageUsData.name[locale]}
        />
      </MainContainer>
      <Footer />
    </>
  );
};

export default AboutUs;
