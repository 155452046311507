import { graphql, useStaticQuery } from 'gatsby';
import JoinPlusIcon from 'images/join.svg';
import React from 'react';
import { Linkedin } from 'styled-icons/boxicons-logos/Linkedin';
import { Twitter } from 'styled-icons/boxicons-logos/Twitter';
import {
  Container,
  JoinIconWrapper,
  JoinUsLink,
  MemberName,
  MemberPhotoWrapper,
  MemberPosition,
  MemberSocialLinksContainer,
  MemberWrapper,
  SocialLink,
} from './styled';

interface Props {
  joinUsText: string;
}

const pageQuery = graphql`
  {
    allTeammemberslistYaml {
      nodes {
        photo {
          publicURL
        }
        name
        position
        twitter
        linkedin
      }
    }
  }
`;

const TeamMembers = ({ joinUsText }: Props) => {
  const data = useStaticQuery(pageQuery);

  return (
    <Container>
      {data.allTeammemberslistYaml.nodes.map((teamMember: any, index: number) => (
        <MemberWrapper key={index}>
          <MemberPhotoWrapper imageLink={teamMember.photo.publicURL}>
            <MemberSocialLinksContainer>
              <SocialLink rel="external" target="_blank" href={teamMember.twitter}>
                <Twitter />
              </SocialLink>
              <SocialLink rel="external" target="_blank" href={teamMember.linkedin}>
                <Linkedin />
              </SocialLink>
            </MemberSocialLinksContainer>
          </MemberPhotoWrapper>
          <MemberName>{teamMember.name}</MemberName>
          <MemberPosition>{teamMember.position}</MemberPosition>
        </MemberWrapper>
      ))}
      <MemberWrapper>
        <JoinUsLink to={'/career'}>
          <JoinIconWrapper>
            <JoinPlusIcon />
          </JoinIconWrapper>
        </JoinUsLink>
        <MemberName>{joinUsText}</MemberName>
      </MemberWrapper>
    </Container>
  );
};

export default TeamMembers;
