import styled, { DefaultTheme, ThemeProps } from 'styled-components';

interface CareerItemProps extends ThemeProps<DefaultTheme> {
  offset: number;
}

interface PositionFocusProps {
  isFocused: boolean;
}

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
`;

export const CareerIntroMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;
`;

export const CareerIntroWrapper = styled.div`
  align-items: flex-start;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    max-width: ${({ offset }) => offset * 150 + 440}px;
    padding-left: ${({ offset }: CareerItemProps) => offset * 100}px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    max-width: ${({ offset }) => offset * 150 + 540}px;
    padding-left: ${({ offset }: CareerItemProps) => offset * 150}px;
  }
`;

export const CareerIntroHeader = styled.h3`
  margin: 0;
  font-weight: 700;
  line-height: 1.1;
  font-size: 26px;
  color: ${({ theme }) => theme.color.text.primary};
  margin-bottom: 30px;
  text-transform: uppercase;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    font-size: 36px;
  }
`;

export const CareerIntroText = styled.p`
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.color.text.secondary};
`;

export const CareerIntroImage = styled.img`
  z-index: 1;
  position: relative;
  max-width: 100%;
`;

export const CareerCarrierDronePurple = styled(CareerIntroImage as any)`
  width: 50%;
  right: 0px;
  top: 20px;
  align-self: flex-end;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    width: 75%;
    top: 100px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    width: 75%;
    top: 120px;
    right: -30px;
  }
`;

export const CareerPhoneBlue = styled(CareerIntroImage as any)`
  max-width: 108px;
  padding-top: 50px;
  top: -40px;
  align-self: flex-end;
  right: 250px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    right: 0;
  }
`;

export const CareerCarrierDroneGreen = styled(CareerIntroImage as any)`
  top: 10px;
  left: 0px;
  width: 50%;
  padding-bottom: 50px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    top: 30px;
    left: -80px;
    width: 60%;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    top: 100px;
    left: -150px;
  }
`;

export const TitleText = styled.h2`
  font-size: 50px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    font-size: 65px;
  }
`;

export const OffersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  flex-wrap: nowrap;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const OfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    width: 45%;
    :nth-child(odd) {
      margin-right: auto;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    width: 30%;
    margin-right: auto;
    :nth-child(3n) {
      margin-right: initial;
    }
  }
`;

export const OfferHeader = styled.h4`
  font-family: 'Roboto Mono';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.text.primary};
  text-align: center;
  margin: 30px 0;
`;

export const OfferText = styled.p`
  font-size: 18px;
  line-height: 40px;
  color: ${({ theme }) => theme.color.text.secondary};
  text-align: center;
  a:link {
    color: ${({ theme }) => theme.color.text.accent};
    background-color: ${({ theme }) => theme.color.background.transparent};
    text-decoration: none;
  }
  a:hover {
    opacity: 0.6;
  }
  a:visited {
    color: ${({ theme }) => theme.color.text.accent};
    background-color: ${({ theme }) => theme.color.background.transparent};
    text-decoration: none;
  }
`;

export const OfferImageWrapper = styled.div`
  height: 90px;
`;

export const OfferTechImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const OfferTechImage = styled.img`
  height: 30px;
  width: auto;
  margin: 5px;
`;

export const PositionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  justify-content: center;
  margin-bottom: 150px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const PositionHeader = styled.h4`
  margin-top: 0;
  margin-bottom: 30px;
  font-family: 'Roboto Mono';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.text.primary};
`;

export const PositionText = styled.span`
  line-height: 30px;
  font-size: 18px;
  color: ${({ theme }) => theme.color.text.secondary};
  margin: 0 0 10px;
`;

export const ImInterested = styled.div`
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto Mono';
  color: ${({ theme }) => theme.color.text.primary};
  text-decoration: none;
`;

export const PositionInnerWrapper = styled.div<PositionFocusProps>`
  padding: 40px;
  background: linear-gradient(0deg, #141212 0, #0e0027 100%);

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 450px;
  margin: 10px auto;

  @media screen and (min-width: ${({ theme }) => theme.layout.sm}px) {
    width: 450px;
    height: 250px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    width: 300px;
    height: 400px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    width: 450px;
    height: 300px;
  }

  ${({ isFocused }) => {
    if (isFocused) {
      return {
        background: 'linear-gradient(-45deg, #5c258d 0%, #e97272 100%)',
        'justify-content': 'center',
        'align-items': 'center',
        padding: '5px',
      };
    }
    return {};
  }};
`;

export const Separator = styled.div`
  margin-bottom: 100px;
`;

export const NotExactMatchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  align-self: flex-start;
`;

export const NotExactMatchHeader = styled.h2`
  color: ${({ theme }) => theme.color.text.primary};
  margin-top: 0;
  margin-bottom: 30px;
  text-align: left;
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const NotExactMatchText = styled.p`
  color: ${({ theme }) => theme.color.text.secondary};
  margin-top: 0;
  text-align: left;
  font-size: 22px;
  max-width: 540px;
  margin-bottom: 100px;
`;

export const GdprText = styled.p`
  font-size: 14px;
  line-height: 1.42857143;
  color: ${({ theme }) => theme.color.text.primary};
  text-align: left;
  margin: 30px 0 40px;
  a:link {
    color: ${({ theme }) => theme.color.text.accent};
    background-color: ${({ theme }) => theme.color.background.transparent};
    text-decoration: none;
  }
  a:hover {
    opacity: 0.6;
  }
  a:visited {
    color: ${({ theme }) => theme.color.text.accent};
    background-color: ${({ theme }) => theme.color.background.transparent};
    text-decoration: none;
  }
`;
