import { RedSubmit } from 'components/CommonComponents/RedButtons';
import { MainTitleText, TitleText } from 'components/CommonComponents/Titles/styled';
import { SectionWithBackground, StyledSection } from 'components/CommonComponents/Wrappers/styled';
import { Separator } from 'components/PageCareer/styled';
import React, { useState } from 'react';
import { ExtraPadding, StyledForm, StyledInput, StyledTextArea } from './styled';

/*
 on submit to info@qest.cz
 TODO: setup nodemailer, file upload, format mail, validation
*/

interface Props {
  mainHeading: string;
  namePlaceholder: string;
  emailPlaceholder: string;
  messagePlaceholder: string;
  buttonText: string;
  imageLink: string;
  titleType?: TitleType;
}

export enum TitleType {
  H1 = 'h1',
  H2 = 'h2',
}

const MessageUs = ({ imageLink, buttonText, mainHeading, namePlaceholder, messagePlaceholder, emailPlaceholder, titleType }: Props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  return (
    <SectionWithBackground imageLink={imageLink}>
      <StyledSection as="div">
        <ExtraPadding />
        {titleType === TitleType.H1 && <MainTitleText>{mainHeading}</MainTitleText>}
        {titleType === TitleType.H2 && <TitleText>{mainHeading}</TitleText>}
        <Separator />
        <StyledForm>
          {false && <StyledInput type="text" value={name} onChange={e => setName(e.target.value)} placeholder={namePlaceholder} />}
          {false && <StyledInput type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder={emailPlaceholder} />}
          {false && <StyledTextArea value={message} onChange={e => setMessage(e.target.value)} placeholder={messagePlaceholder} />}
          <RedSubmit
            disabled={false}
            value={buttonText}
            onClick={() => {
              // @TODO: on submit send to somewhere
              window.open('mailto:info@qest.cz');
            }}
          />
        </StyledForm>
      </StyledSection>
    </SectionWithBackground>
  );
};

export default MessageUs;
